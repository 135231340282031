<template>
    <div>
        <div>
            <div class="content-header container-fluid bg-white">
                <div class="row">
                    <div class="col-sm-4">
                        <h4><i class="ti-files"> </i> 检查巡检 | 删除日志</h4>
                    </div>
                </div>
            </div>
            <div class="content">
                <!-- <div class="row">
                    <div class="col-md-12">
                        <el-button type="primary" @click="rowEdit()">新增记录</el-button>
                        <el-button type="success" @click="exportExcel()">导出Excel</el-button>
                    </div>

                </div> -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="w-block" style="padding: 2px;">

                            <!-- <el-date-picker v-model="grid.sea.START_DT" value-format="yyyy-MM-dd" @change="filter"
                                type="date" placeholder="选择开始日期">
                            </el-date-picker>
                            <el-date-picker v-model="grid.sea.END_DT" value-format="yyyy-MM-dd" @change="filter"
                                type="date" placeholder="选择结束日期">
                            </el-date-picker> -->
                            <el-select v-model="grid.sea.PLATFORM" @change="filter" placeholder="请选择状态">
                                <el-option :value="0" label="全部" />
                                <el-option :value="1" label="电脑" />
                                <el-option :value="2" label="手机" />
                            </el-select>
                            <el-button slot="append" icon="el-icon-search" @click="filter"></el-button>
                        </div>
                        <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total"
                            :page-index="grid.sea.PAGE_INDEX+1" :page-size="grid.sea.PAGE_SIZE"
                            @page-changed="loadPage">
                            <el-table-column prop="CREATE_NAME" label="删除用户" align="center" />
                            <el-table-column prop="CREATE_TIME" label="删除日期" align="center" />
                            <el-table-column prop="PLATFORM" label="删除平台" align="center">
                                <template slot-scope="scope">
                                    <el-tag v-if="scope.row.PLATFORM==1" type="info"> 电脑</el-tag>
                                    <el-tag v-else-if="scope.row.PLATFORM==2" type="danger"> 手机</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column prop="CONTENT" label="删除内容" align="center" />
                        </wgrid>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>

    import Wgrid from "@/components/wgrid";
    import jsFileDownload from 'js-file-download'
    export default {
        name: "index",
        components: { Wgrid },
        data() {
            return {
                grid: {
                    sea: {
                        PAGE_SIZE: 12,
                        PAGE_INDEX: 0,
                        PLATFORM: 0
                    },
                    ls: [],
                    total: 0,
                    loading: false
                },
            }
        },
        created() {
            this.getList();
        },
        methods: {
            filter() {
                this.grid.sea.PAGE_INDEX = 0;
                this.grid.ls = [];
                this.getList();
            },
            loadPage(pi) {
                this.grid.sea.PAGE_INDEX = pi - 1;
                this.getList();
            },
            getList() {
                let self = this;
                this.grid.loading = true;
                this.whale.remote.getCollection({
                    url: "/api/School/SAFE/RecApi/GetDeleteLog",
                    data: this.grid.sea,
                    completed: function (its, n) {
                        self.grid.ls = its;
                        self.grid.total = n;
                        self.grid.loading = false;
                    }
                })
            },



        }
    }
</script>